import React from "react";

export function TrashIcon(props:React.SVGProps<SVGSVGElement>){
  return (
    <svg {...props} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Interface / Trash_Full">
        <path id="Vector" d="M14.5 10L14.5 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
               <path id="Vector_2" d="M10.5 10L10.5 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_3" d="M18.5 6H6.5V20C6.5 20.5523 6.94772 21 7.5 21H17.5C18.0523 21 18.5 20.5523 18.5 20V6Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_4" d="M4.5 6H20.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_5" d="M15.5 3H9.5C8.94772 3 8.5 3.44772 8.5 4V6H16.5V4C16.5 3.44772 16.0523 3 15.5 3Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>
    )
}