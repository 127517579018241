import {useEffect, useState} from "react";
import Container from "../../components/Container/Container";

import './Dev.css'


export function Dev() {
    const [registr, setRegisr] = useState(false)

    console.log(window.Telegram)

    useEffect(() => {
        navigator.serviceWorker.ready.then(registration => setRegisr(true))
    }, []);

    return (
        <Container className='pt-20'>
            <div className='column dev-container'>
                <label html-for="localStorage">
                    <input type="checkbox" checked={'localStorage' in window} id={'localStorage'} value={'localStorage'}
                           style={{color: 'green'}}/>
                    localStorage
                </label>
                <label html-for="ServiceWorker">
                    <input type="checkbox" checked={'ServiceWorker' in window} id={'ServiceWorker'}
                           value={'ServiceWorker'} style={{color: 'green'}}/>
                    ServiceWorker
                </label>
                <label html-for="indexedDB">
                    <input type="checkbox" checked={'indexedDB' in window} id={'indexedDB'} value={'indexedDB'}
                           style={{color: 'green'}}/>
                    indexedDB
                </label>
                <label html-for="sessionStorage">
                    <input type="checkbox" checked={'sessionStorage' in window} id={'sessionStorage'}
                           value={'sessionStorage'}
                           style={{color: 'green'}}/>
                    sessionStorage
                </label>
                <label html-for="tg">
                    <input type="checkbox" checked={'Telegram' in window} id={'tg'} value={'tg'}
                           style={{color: 'green'}}/>
                    Telegram
                </label>
                <div className='scroll'>
                    <pre>
                        {JSON.stringify(window.Telegram.WebApp.initDataUnsafe.start_param || {}, undefined, 2)}
                    </pre>

                    {registr &&
                    <div>sw ready</div>
                    }

                    {navigator.userAgent}
                </div>
            </div>

        </Container>
    )
}