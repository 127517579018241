import React from "react";

export function PhotoIcon(props:React.SVGProps<SVGSVGElement>){
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="add photo">
        <path id="Vector 34" d="M13 5H9.60555C9.2107 5 8.82469 5.11688 8.49615 5.3359L6.50385 6.6641C6.17531 6.88312 5.7893 7 5.39445 7H4C2.89543 7 2 7.89543 2 9V19C2 20.1046 2.89543 21 4 21H18C19.1046 21 20 20.1046 20 19V12" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
           <path id="Vector 35" d="M20 2V8M17 5H23" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                <circle id="Ellipse 39" cx="11" cy="14" r="4" stroke="currentColor" strokeWidth="2"/>
            </g>
        </svg>

    )
}