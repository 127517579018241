import React from 'react';

export function AirplaneIcon(props:React.SVGProps<SVGSVGElement>) {
    return (
        <svg {...props} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="airplanemode_active_24px">
                <path id="icon/device/airplanemode_active_24px" d="M21.2002 16V14L13.2002 9V3.5C13.2002 2.67 12.5302 2 11.7002 2C10.8702 2 10.2002 2.67 10.2002 3.5V9L2.2002 14V16L10.2002 13.5V19L8.2002 20.5V22L11.7002 21L15.2002 22V20.5L13.2002 19V13.5L21.2002 16Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
            </g>
        </svg>
    );
}

