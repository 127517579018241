import React from "react";

export function MapIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 6L21 3V18L15 21V6Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
         strokeLinejoin="round"/>
      <path d="M9 18L15 21V6L9 3V18Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
         strokeLinejoin="round"/>
      <path d="M3 6L9 3V18L3 21V6Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
         strokeLinejoin="round"/>
    </svg>
  )
}